<template>
  <div style="height: 15%; min-height: 50px" class="visitor-list">
    <!----------- Data Table Header -------------->
    <v-card-title
      class="px-0 mt-3 pb-0 datatable-header-container"
      fluid
    >
      <div 
        class="datatable-header-checkin"
        v-if="!$vuetify.breakpoint.xs"
      >
        <div
          aria-labelledby="showingLabel"
          class="visitor-type-filter-container"
        >
          <div 
            class="body-2 pr-4 datatable-header-text"
            id="showingLabel"
            v-if="!isMobileView()"
          >
            Filter by:
          </div>

          <v-select
            dense
            outlined
            return-object
            background-color="white"
            label="Visitor type"
            :items="visitorTypes"
            v-on:change="setVisitorFilter"
            item-text="name"
            :readonly="evacuationMode"
          ></v-select>

          <v-select
            dense
            outlined
            return-object
            background-color="white"
            label="Induction"
            :items="inductionOptions"
            v-on:change="setInductionOptionFilter"
            item-text="name"
            :readonly="evacuationMode"
          ></v-select>
        </div>

        
        <span v-if="!evacuationMode && !isLoading">
          <b>{{ visitorsCount }}</b> check-ins
          {{ isTabToday ? "for" : "from" }} {{ checkDateTile }}
        </span>
      </div>

      <div class="datatable-header-right">
        <span 
          class="body-2 pr-4 datatable-header-text"
          v-if="!isMobileView()"
        >
          For:
        </span>

        <v-btn-toggle
          class="date-btn-group"
          v-model="toggleDateRange"
          mandatory
          @change="onChangeDateRange"
        >
          <v-btn
            id="button-group-today"
            aria-label="Show visitors for today"
            aria-current="true"
            class="ma-0"
            :class="getHeadBtnPadding()"
            outlined
            :disabled="evacuationMode"
          >
            <span>Today</span>
          </v-btn>

          <v-btn
            id="button-group-this-week"
            aria-label="Show visitors for this week"
            aria-current="false"
            class="ma-0"
            :class="getBtnPadding()"
            outlined
            :disabled="evacuationMode"
          >
            <span>This Week</span>
          </v-btn>

          <v-btn
            id="button-group-this-month"
            aria-label="Show visitors for this month"
            aria-current="false"
            class="ma-0"
            :class="getBtnPadding()"
            outlined
            :disabled="evacuationMode"
          >
            <span>This Month</span>
          </v-btn>

          <v-btn
            id="button-group-date-range"
            aria-label="Show visitors for a selected date range"
            aria-expanded="false"
            class="ma-0"
            v-if="!$vuetify.breakpoint.xs"
            outlined
            :disabled="evacuationMode"
          >
            <span>Date Range</span>
            <v-icon class="pl-1" :class="showDateRange ? 'white-icon' : ''"
              >arrow_drop_down</v-icon
            >
          </v-btn>

          <!-- Mobile view -->
          <v-btn
            class="ma-0"
            :class="getTailBtnPadding()"
            v-if="$vuetify.breakpoint.xs"
            outlined
            :disabled="evacuationMode"
          >
            <v-icon :class="showDateRange ? 'white-icon' : ''"
              >calendar_today</v-icon
            >
          </v-btn>
        </v-btn-toggle>
        
        <!-- Announcements to assistive technology users when table content updates -->
        <p role="status" class="d-sr-only" id="tableShowingStatusMsg">Table showing all visitors for today</p>

        <!-- Export Data Button -->
        <export-data-btn
          :hideExportDataBtn="isMobileView()"
        ></export-data-btn>

        <!-- Actions Button -->
        <!-- <action-menu 
          class="actions-button-container" 
          id="actionsButton"
          :isDisabled="selected.length < 1"
          :actionItems="actionItems"
          :records="selected"
          @markEntryFinished="onMarkEntryFinished"
          ></action-menu> -->
      </div>
    </v-card-title>
    <div id="dateErrorMessage" >Date is not a valid date. Please use 'DD/MM/YYYY'</div>
    <v-row 
      class="ma-0 align-center" 
      no-gutters 
      :justify="screenSize < 600 ? 'space-between' : 'end'"
      id="dateConsole"
    >
        <v-col cols="12" sm="12" md="8" lg="6">
          <v-card-title class="pa-0 justify-end" v-if="showDateRange" outlined>
            <v-card class="pa-2" outlined tile>
              <v-row align="end" no-gutters>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      role="textbox"
                      aria-haspopup="false"
                      aria-expanded="false"
                      aria-autocomplete="none"
                      aria-placeholder="Enter a date in format DD/MM/YYYY"
                      aria-labelledby="fromDateLabel"
                      :aria-describedby="isFromDateInvalid ? 'dateErrorMessage' : ''"
                      v-model="dateFromFormatted"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      placeholder="DD/MM/YYYY"
                      :rules="[validationRules.fromDate]"
                      @input="handleFromDateEntered"
                      @update:error="isFromDateInvalid = $event"
                    >
                      <template v-slot:label>
                        <span id="fromDateLabel" class="date-picker-label">From Date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFromFilter"
                    @input="menu1 = false"
                    :min="minDate"
                    :max="maxDate"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      role="textbox"
                      aria-haspopup="false"
                      aria-expanded="false"
                      aria-autocomplete="none"
                      aria-placeholder="Enter a date in format DD/MM/YYYY"
                      aria-labelledby="toDateLabel"
                      :aria-describedby="isToDateInvalid ? 'dateErrorMessage' : ''"
                      v-model="dateToFormatted"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      placeholder="DD/MM/YYYY"
                      :rules="[validationRules.toDate]"
                      @input="handleToDateEntered"
                      @update:error="isToDateInvalid = $event"
                    >
                      <template v-slot:label>
                        <span id="toDateLabel" class="date-picker-label">To Date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateToFilter"
                    @input="menu2 = false"
                    :min="dateFromFilter"
                    :max="maxDate"
                  ></v-date-picker>
                </v-menu>
                <AdsButton
                  depressed
                  class="apply-btn"
                  @click="onDateRangeFiltering"
                  :disabled="evacuationMode"
                >
                  APPLY
                </AdsButton>
              </v-row>
            </v-card>
          </v-card-title>
        </v-col>
      </v-row>

    <!-----------Data Table Body -------------->
    <AdsDataTable
      v-model="selected"
      show-select
      :headers="$vuetify.breakpoint.xs ? mobileHeaders : headers"
      :items="visitorsList"
      item-key="event_id"
      :search="search"
      sort-by="name"
      search-label="Search by name"
      search-aria-label="Search by name"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      @click:row="rowAction"
      sort-desc
    >
      <template v-slot:item.visitor.namephone="{ item }">
        <span>
          {{item['visitor.name']}} <br/>
          Ph: {{item['visitor.phone']}}
        </span>
      </template>

      <template v-slot:item.checkInDate="{ item }">
        <span>
          {{ formatDateToShowForTable(item.checkInDate) }}
        </span>
      </template>

      <template v-slot:item.visitor.type="{ item }">
        {{item['visitor.type']}}
        <span v-if="item['visitor.type'] === 'Contractor'" >
          <br/>
          Job: {{item['visitor.job_id']}}
        </span>
      </template>

      <template v-slot:item.visitor.site_safety_induction="{ item }">
        <span v-if="item['visitor.type'] === 'Parent/Carer'" />
        <span v-else>
          <v-icon color="#00AA45" v-if="(item != null && (item['visitor.site_safety_induction'] === 'true' || item['visitor.site_safety_induction'] === true))">mdi-check-circle</v-icon>
          <v-icon v-else>mdi-cancel</v-icon>
          {{ (item != null && (item['visitor.site_safety_induction'] === 'true' || item['visitor.site_safety_induction'] === true)) ? "Completed" : "Not completed" }}
          
        </span>
      </template>

      <template v-slot:item.visitor.white_card_provided="{ item }">
        <span v-if="(item != null && item['visitor.type'] === 'Contractor' && (item['visitor.white_card_provided'] === 'true' || item['visitor.white_card_provided'] === true))" >
          <v-icon color="#00AA45">mdi-check-circle</v-icon>
          <span> Verified</span>
        </span>
        <span v-else-if="(item != null && item['visitor.type'] === 'Contractor')" >
          <v-icon color="#D7153A" icon="mdi-alert">mdi-alert</v-icon>
          <span> Not verified</span>
        </span>
        <span v-else>
          <v-icon color="#495054" icon="mdi-block-helper">mdi-block-helper</v-icon>
          <span> N/A</span>
        </span>
      </template>

      <template v-slot:item.timeOut="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="!item.checkout_timestamp && item.timeOut"
              color="#DC5800"
              text-color="white"
              small
              v-bind="attrs"
              v-on="on"
            >
            {{ item.timeOut }}
            </v-chip>
          </template>
          <span>
            This indicates that the user did not check out (inclusive of the day of check-in and up until 3am of the current day)
          </span>
        </v-tooltip>
        <span
          v-if="item.checkout_timestamp"
        >
          {{ item.timeOut }}
        </span>
      </template>

      <template v-slot:item.doe_entry_requirements_obj="{ item }">
        <v-icon 
          :class="[`material-icons-${item.doe_entry_requirements_obj.iconStyle}`, 'entry-requirements-icon']"
          :color="`${item.doe_entry_requirements_obj.iconColor}`"
        >
        {{ item.doe_entry_requirements_obj.icon }}
        </v-icon>
        <span>{{ item.doe_entry_requirements_obj.value }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <visitor-view
          :record="item"
        ></visitor-view>

        <v-icon
          class="material-icons-outlined icon-color"
          aria-label="Print visitor label"
          @click="printItem(item)"
        >
          print
        </v-icon>
      </template>

      <!-- Mobile visitor name column -->
      <template 
        v-slot:item.visitor.name="{ item }"
        v-if="$vuetify.breakpoint.xs"
      >
        <span class="text-body-1">
          {{ item['visitor.name'] }} <br/>
          Ph: {{ item['visitor.phone'] }}
        </span>
      </template>

      <!-- Mobile phone column -->
      <template 
        v-slot:item.visitor.phone="{ item }"
        v-if="$vuetify.breakpoint.xs"
      >
        <a 
          class="phone-number--anchor" 
          :href="getPhoneLink(item['visitor.phone'])"
          @click="onPhoneClick"
        >
          {{ item['visitor.phone'] }}
        </a>
      </template>

      <!-- Mobile time in column -->
      <template 
        v-slot:item.timeIn="{ item }"
        v-if="$vuetify.breakpoint.xs"
      >
        <span>
          <b>From:</b> {{ item.timeIn }} <b>To:</b> 
          <span
            v-if="!item.checkout_timestamp && item.timeOut"
            class="expired-color"
          >
          {{ item.timeOut }}
          </span>
          <span
            v-if="item.checkout_timestamp"
          >
            {{ item.timeOut }}
          </span>
        </span>
      </template>

      <!-- Mobile entry requirements column -->
      <!-- <template
        v-slot:item.doe_entry_requirements="{ item }"
        v-if="$vuetify.breakpoint.xs"
      >
        <b>Entry requirements: </b>
        <span>{{ item.doe_entry_requirements_obj.value }}</span>
      </template> -->
    </AdsDataTable>

    <!-- Visitor View Mobile -->
    <visitor-view-mobile
      v-if="mobileModalOn"
      :record="modalRecord"
      ></visitor-view-mobile>

    <!-- Evacuation Switch -->
    <evacuation-switch
      id="evacuationSwitch"
      @actionFired="onActionFired"
    ></evacuation-switch>

    <!-- Evacuation List Print -->
    <evacuation-list-print-btn
      id="evacuationListPrint"
      :records="visitorsList"
      :schoolName="selectedSchoolName"
    ></evacuation-list-print-btn>

    <!-- Checkout Button -->
    <checkout-btn
      id="checkoutBtn"
      :checkoutDisabled="checkoutDisabled"
      @actionFired="onActionFired"
    ></checkout-btn>

    <!-- Checkout Failure Alert -->
    <checkout-failure
      id="checkoutFailureAlert"
      :checkoutConfirming="checkoutConfirming"
      @checkoutAlertReset="onCheckoutAlertReset"
    ></checkout-failure>

    <!-- Bulk Print Button -->
    <bulk-print-btn
      id="bulkPrintBtn"
      :bulkPrintDisabled="checkoutDisabled"
      :selected-records="selected"
      @actionFired="onActionFired"
    ></bulk-print-btn>

    <!-- Action menu -->
    <!-- <action-menu
      id="actionsButton"
     :is-disabled="checkoutDisabled"
     :selected-records="selected"
     @actionFired="onActionFired"
     :action-items="!isMobileView() ? 
                    [
                      { action: 'Check out', key: 'checkOut', icon: 'cancel'}, 
                      { action: 'Print label', key: 'printLabel', icon: 'print' }
                    ]
                    : [
                      { action: 'Check out', key: 'checkOut', icon: 'cancel'}
                    ]"
    ></action-menu> -->

    <!----------- Access Denied Alert -------------->
    <v-container>
      <Alert
        :showAlert="accessDenied"
        type="error"
        text="Access Denied"
        subtext="Your session has expired. Please log in again to continue"
        actionText="LOGIN TO CONTINUE"
        :actionHandler="refreshPage"
        position="top"
        border="top"
      >
      </Alert>
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { mapGetters, mapActions } from 'vuex';
import { AdsDataTable, Alert, AdsButton } from '@nswdoe/doe-ui-core';
import VisitorViewMobile from './modal/VisitorViewMobile.vue';
import EvacuationSwitch from './EvacuationSwitch.vue';
import VisitorView from './modal/VisitorView.vue';
import EvacuationListPrintBtn from './EvacuationListPrintBtn.vue';
import CheckoutBtn from './CheckoutBtn.vue';
import CheckoutFailure from './modal/CheckoutFailure.vue';
import BulkPrintBtn from './BulkPrintBtn.vue';
import ExportDataBtn from './ExportDataBtn.vue';
// import ActionMenu from './ActionMenu.vue';

// Const Parameters
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export default {
  name: "VisitorList",
  components: {
    AdsDataTable,
    Alert,
    VisitorViewMobile,
    EvacuationSwitch,
    VisitorView,
    EvacuationListPrintBtn,
    CheckoutBtn,
    CheckoutFailure,
    AdsButton,
    BulkPrintBtn,
    ExportDataBtn,
    // ActionMenu,
  },
  data: (vm) => ({
    // return {
    dateFromFilter: new Date().toISOString().substr(0, 10),
    dateToFilter: new Date().toISOString().substr(0, 10),
    dateToFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    dateFromFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    minDate: "2021-02-01",
    maxDate: dayjs().format('YYYY-MM-DD'),
    modal: false,
    menu2: false,
    menu1: false,
    search: "",
    loading: false,
    toggleDateRange: null,
    singleDate: null,
    checkDateTile: "Today",
    showDateRange: false,
    allowFuture: false,
    sortBy: "checkInDate",
    sortDesc: false,
    isTabToday: true,
    headers: [
      { text: "Date", value: "checkInDate" },
      { text: "Name", value: "visitor.namephone" },
      //{ text: "Phone", value: "visitor.phone" },
      { text: "Visitor type", value: "visitor.type" },
      { text: "Induction", value: "visitor.site_safety_induction" },
      // { text: "White Card", value: "visitor.white_card_provided" },
      { text: "Reason for visit", value: "purpose" },
      //{ text: "Job ID", value: "visitor.job_id"},
      { text: "In", value: "timeIn" },
      { text: "Out", value: "timeOut" },
      // { text: "Entry requirements", value: "doe_entry_requirements_obj" },
      { text: "", value: "actions", sortable: false },
    ],
    mobileHeaders: [
      { text: "Date", value: "checkInDate" },
      { text: "Name", value: "visitor.name" },
      // { text: "Phone", value: "visitor.phone"},
      { text: "Organisation", value: "visitor.company_name" },
      { text: "Visitor type", value: "visitor.type" },
      { text: "In", value: "timeIn" },
      // { text: "Entry requirements", value: "doe_entry_requirements" },
    ],
    visitorTypes: [
      { name: "All visitors", value: "" },
      { name: "Parent/Carer", value: "parent" },
      { name: "Volunteer/Parent volunteer", value: "volunteer" },
      { name: "Contractor", value: "contractor" },
      { name: "School staff/Other", value: "other" },
    ],
    inductionOptions: [
      { name: "All", value: "" },
      { name: "Completed", value: "true" },
      { name: "Not completed", value: "false" },
    ],
    selected: [],
    screenSize: window.innerWidth,
    accessDenied: false,
    validationRules: {
      fromDate: (val) => {
        const isValid = !val || val === "" ? false : vm.parseFromDate(val) != null;

        return (isValid ||`Date is not a valid date. Please use 'DD/MM/YYYY'`);
      },
      toDate: val => {
        const isValid = !val || val === "" ? false : vm.parseToDate(val) != null;

        return (isValid ||`Date is not a valid date. Please use 'DD/MM/YYYY'`);
      },
    },
    modalRecord: undefined,
    filterStore: {},
    phoneClicked: false,
    actionItems: [
      {key: 'Complete', icon: 'check_circle', action: 'Mark as entry requirements met'},
      {key: 'Not met',icon: 'not_interested', action: 'Mark as entry requirements not met'},
    ],
    checkoutDisabled: true,
    checkoutConfirming: false,
    isFromDateInvalid: false,
    isToDateInvalid: false,
  }),
  beforeMount() {
    // required for parser
    dayjs.extend(customParseFormat);

    const currentdate = new Date();
    this.checkDateTile = `${currentdate.getDate()} ${
      monthNames[currentdate.getMonth()]
    } ${currentdate.getFullYear()}`;
  },
  mounted() {
    // reposition components
    if (this.screenSize < 600) {
      // evacuation switch
      const evacuationSwitch = this.$el.querySelector('#evacuationSwitch');
      // const actionsBtn = this.$el.querySelector('#actionsButton');
      const dateConsole = this.$el.querySelector("#dateConsole");
      const checkoutBtn = this.$el.querySelector('#checkoutBtn');

      evacuationSwitch.style.marginTop = '8px';

      dateConsole.appendChild(evacuationSwitch);
      dateConsole.appendChild(checkoutBtn);
      // dateConsole.appendChild(actionsBtn);

      // search bar
      const searchBar = this.$el.querySelector('div.v-data-table.dataTable[item-key="event_id"]').firstChild;
      searchBar.style.background = '#FFF';
      searchBar.style.marginBottom = '15px';
      searchBar.id = 'datatable-search-bar';
      const headerContainer = this.$el.querySelector('div.v-card__title.datatable-header-container');
      headerContainer.style.padding = 0;

      headerContainer.appendChild(searchBar);
    } else {
      // evacuation switch
      const evacuationSwitch = this.$el.querySelector('#evacuationSwitch');
      evacuationSwitch.style.marginRight = '18px';
      const searchDiv = this.$el.querySelector('.searchInput');
      // evacuation list print
      const evacuationListPrint = this.$el.querySelector('#evacuationListPrint');
      // checkout button
      const checkoutBtn = this.$el.querySelector('#checkoutBtn');
      checkoutBtn.style.marginRight = '18px';
      // bulk print button
      const bulkPrintBtn = this.$el.querySelector('#bulkPrintBtn');

      // const actionsBtn = this.$el.querySelector('#actionsButton');
      // actionsBtn.style.marginLeft = '18px';

      searchDiv.appendChild(evacuationListPrint);
      searchDiv.appendChild(evacuationSwitch);
      searchDiv.appendChild(checkoutBtn);
      searchDiv.appendChild(bulkPrintBtn);
      // searchDiv.appendChild(actionsBtn);
      
    }

    // data table message color change
    const cell = this.$el.querySelector('tbody tr td')
    if (cell.innerHTML === 'No data available' || cell.innerHTML === 'Loading...Please Wait') {
      cell.style.color = 'black';
    }
  },
  computed: {
    visitorsList() {
      return this.visitors;
    },
    visitorsCount() {
      let count = 0;

      if (Array.isArray(this.visitors) && this.visitors.length > 0) {
        this.visitors.map((visitor) => {
          if (dayjs().isSame(dayjs(visitor['checkInDate']), 'day')) {
            count++;
          }
        });
      }

      return count;
    },
    ...mapGetters('visitors', [
      'visitors',
      'selectedVenueId',
      'isLoading',
      'visitorType',
      'selectedInductionOption',
      'errors',
      'mobileModalOn',
      'evacuationMode',
      'dateFrom',
      'dateTo',
      'selectedSchoolName',
      'selectedEventIds',
      'storedEventIds',
      'selectedEvents',
    ]),
    ...mapGetters('schools', [
      'schools',
    ]),
  },
  watch: {
    evacuationMode() {
      if (this.evacuationMode) {
        // store current filter config
        this.filterStore.selectedVenueId = this.selectedVenueId;
        this.filterStore.dateFrom = this.dateFrom;
        this.filterStore.dateTo = this.dateTo;
        this.filterStore.visitorType = this.visitorType;

        // get evacuation list
        const currentDate = new Date();
        const dateFrom = this.dateToString(currentDate);
        const dateTo = this.dateToString(currentDate);

        this.setFilters({
          selectedVenueId: this.selectedVenueId,
          dateFrom,
          dateTo,
          visitorType: '',
          selectedInductionOption: '',
        });
      } else {
        // restore filter config
        this.setFilters({
          selectedVenueId: this.filterStore.selectedVenueId,
          dateFrom: this.filterStore.dateFrom,
          dateTo: this.filterStore.dateTo,
          visitorType: this.filterStore.visitorType,
          selectedInductionOption: this.filterStore.selectedInductionOption,
        });
      }
    },
    dateFromFilter() {
      this.dateFromFormatted = this.formatDate(this.dateFromFilter);
    },
    dateToFilter() {
      this.dateToFormatted = this.formatDate(this.dateToFilter);
    },
    visitorsCount() {
      this.setTodayCheckinsCount(this.visitorsCount);
    },
    selected() {
      // update selectedEventIds
      this.setSelectedEventIds(this.extractEventIds(this.selected));
      // update selectedEvents
      this.setSelectedEvents(this.extractCheckoutEvents(this.selected));

      // toggle check-out button
      if (this.selected.length > 0) {
        this.checkoutDisabled = false;
      } else {
        this.checkoutDisabled = true;
      }
    },
    errors() {
      if (this.errors.length > 0) {
        const accessDeniedErrors = this.errors.filter(error => {
          if (error.response && error.response.statusCode) {
            return error.response.statusCode = 403;
          }
        });

        if (accessDeniedErrors.length < 0) {
          this.accessDenied = false;
          
          return;
        }

        this.accessDenied = true;

        // TODO - remove the hacky way for timeout.
        setTimeout(() => { this.accessDenied = false; }, 5000);

      } else {
        this.accessDenied = false;
      }
    },
  },
  methods: {
    extractCheckoutEvents(arr) {
      if (arr.length < 1) {
        return [];
      }

      const result = [];

      arr.map((visitor) => {
        result.push({
          'event_id': visitor.event_id,
          'event_source': visitor.event_source,
          });
      });

      return result;
    },
    extractEventIds(arr) {
      if (arr.length < 1) {
        return [];
      }

      const result = [];

      arr.map((visitor) => {
        result.push(visitor.event_id);
      });

      return result;
    },
    onCheckoutAlertReset() {
      this.checkoutConfirming = false;
    },
    onActionFired(val) {
      if (val === 'checkOut') {
        // temporarily store the selected records for check-out confirmation
        this.setStoredEventIds(this.selectedEventIds);

        // empty the selected array
        this.selected = [];

        // confirm the check-out result 15s later
        setTimeout(() => {
          this.checkoutConfirming = true;
        }, 15000);
      } else {
        this.selected = [];
      }
    },
    onPhoneClick() {
      this.phoneClicked = true;

      // remove the flag after 500ms
      setTimeout(() => {
        this.phoneClicked = false;
      }, 500);
    },
    getPhoneLink(phoneNumber) {
      if (!phoneNumber) {
        return '';
      }
      
      return `tel:+61${phoneNumber.substr(1)}`;
    },
    openHelpPage() {
      window.open(
        'https://portal.education.nsw.gov.au/portaldata/visitor-checkin/Tech_QRG.pdf',
        '_blank'
      );
    },
    isMobileView() {
      return window.innerWidth < 960;
    },
    rowAction(val) {
      if (this.$vuetify.breakpoint.xs && !this.phoneClicked) {
        this.setMobileModalOn(true);

        this.modalRecord = val;
      }
    },
    refreshPage() {
      window.location.reload();
    },
    getHeadBtnPadding() {
      if (this.screenSize < 400) {
        return 'px-4';
      }

      if (this.screenSize < 600) {
        return 'px-6';
      }

      return '';
    },
    getTailBtnPadding() {
      if (this.screenSize < 400) {
        return 'px-4';
      }

      if (this.screenSize < 600) {
        return 'px-7';
      }

      return '';
    },
    getBtnPadding() {
      if (this.screenSize < 400) {
        return 'px-3';
      }

      if (this.screenSize < 600) {
        return 'px-3';
      }

      return '';
    },
    printItem(item) {
      const routeData = this.$router.resolve({ name: 'print' });
      // store data
      sessionStorage.setItem('selectedRecords', JSON.stringify([item]));

      window.open(routeData.href, '_blank');
    },
    setVisitorFilter(item) {
      if (item) {
        this.setFilters({ visitorType: item.value, selectedInductionOption: this.selectedInductionOption });
      }
    },
    setInductionOptionFilter(item) {
      if (item) {
        this.setFilters({ visitorType: this.visitorType, selectedInductionOption: item.value });
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (!isDesc[0]) {
          return a[index[0]] < b[index[0]] ? -1 : 1;
        } else {
          return b[index[0]] < a[index[0]] ? -1 : 1;
        }
      });
      return items;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    dateToString: (dateToConvert) => {
      const month = dateToConvert.getMonth() + 1;
      const day = dateToConvert.getDate();

      return `${dateToConvert.getFullYear()}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    },
    formatDateToShowForTile(dateToShow) {
      return `${dateToShow.getDate()} ${
        monthNames[dateToShow.getMonth()]
      } ${dateToShow.getFullYear()}`;
    },
    formatDateToShowForTable(timestamp) {
      const dateObj = new Date(timestamp);

      if (this.$vuetify.breakpoint.xs) {
        return dayjs(timestamp).format('DD MMMM YYYY')
      }

      return `${dateObj.getDate().toString().padStart(2, "0")}/${(
        dateObj.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${dateObj.getFullYear()}`;
    },
    onDateRangeFiltering() {
      const fromDate = new Date(this.dateFromFilter);
      const toDate = new Date(this.dateToFilter);
      this.setFilters({
        selectedVenueId: this.selectedVenueId,
        dateFrom: this.dateToString(fromDate),
        dateTo: this.dateToString(toDate),
        visitorType: this.visitorType,
        selectedInductionOption: this.selectedInductionOption,
      });
      this.checkDateTile =
        this.formatDateToShowForTile(fromDate) +
        " to " +
        this.formatDateToShowForTile(toDate);
      
      // update the table showing status
      this.updateTableShowingStatus(-1);
    },
    onChangeDateRange() {
      this.isTabToday = false;
      this.showDateRange = false;
      let currentdate = new Date();
      let dateFrom = null;
      let dateTo = null;
      let dtFirstday = null;
      let dtLastday = null;
      let todayButton, thiWeekButton, thisMonthButton, dateRangeButton;

      // reset arial states
      if (this.$el) {
        todayButton = this.$el.querySelector('#button-group-today');
        thiWeekButton = this.$el.querySelector('#button-group-this-week');
        thisMonthButton = this.$el.querySelector('#button-group-this-month');
        dateRangeButton = this.$el.querySelector('#button-group-date-range');

        if (todayButton) {
          todayButton.ariaCurrent = 'false';
        }
        if (thiWeekButton) {
          thiWeekButton.ariaCurrent = 'false';
        }
        if (thisMonthButton) {
          thisMonthButton.ariaCurrent = 'false';
        }
        if (dateRangeButton) {
          dateRangeButton.ariaExpanded = 'false';
        }
      }

      switch (this.toggleDateRange) {
        case 0: // today
          dateFrom = this.dateToString(currentdate);
          dateTo = this.dateToString(currentdate);
          this.checkDateTile = this.formatDateToShowForTile(currentdate);
          this.isTabToday = true;
          
          if (todayButton) {
            todayButton.ariaCurrent = "true";
          }
          break;
        case 1: // this week mon-sun
          // if today is Sunday then shift it back to Saturday. Sunday is the starting day of a week.
          if (currentdate.getDay() === 0) {
            currentdate = dayjs(currentdate).subtract(1, "day").toDate();
          }

          dtFirstday = dayjs(currentdate)
            .startOf("week")
            .add(1, "day")
            .toDate();
          dtLastday = dayjs(currentdate).endOf("week").add(1, "day").toDate();
          dateFrom = this.dateToString(dtFirstday);
          dateTo = this.dateToString(dtLastday);
          this.checkDateTile =
            this.formatDateToShowForTile(dtFirstday) +
            " to " +
            this.formatDateToShowForTile(dtLastday);

          if (thiWeekButton) {
            thiWeekButton.ariaCurrent = "true";
          }
          break;
        case 2: // this month
          dtLastday = new Date(
            currentdate.getFullYear(),
            currentdate.getMonth() + 1,
            0
          );
          dateFrom = `${currentdate.getFullYear()}-${(
            currentdate.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-01`;
          dateTo = this.dateToString(dtLastday);

          this.checkDateTile = `1 ${
            monthNames[currentdate.getMonth()]
          } ${currentdate.getFullYear()} to `;
          this.checkDateTile += `${dtLastday.getDate()} ${
            monthNames[dtLastday.getMonth()]
          } ${dtLastday.getFullYear()} `;
          
          if (thisMonthButton) {
            thisMonthButton.ariaCurrent = "true";
          }
          break;
        case 3:
          // should open date range
          this.showDateRange = true;
          
          if (dateRangeButton) {
            dateRangeButton.ariaExpanded = "true";
          }
          break;
      }
      if (this.toggleDateRange < 3) {
        // update the table showing status
        this.updateTableShowingStatus(this.toggleDateRange);

        this.setFilters({
          selectedVenueId: this.selectedVenueId,
          dateFrom: dateFrom,
          dateTo: dateTo,
          visitorType: this.visitorType,
          selectedInductionOption: this.selectedInductionOption,
        });
      }
    },
    updateTableShowingStatus(caseNumber) {
      if (this.$el) {
        let statusMsg = '';
        const visitorType = this.visitorType || 'all visitors';
        const tableShowingStatusMsgContainer = this.$el.querySelector('#tableShowingStatusMsg');

        switch (caseNumber) {
          case -1:
            statusMsg = `Table showing ${visitorType} from ${this.dateFromFormatted} to ${this.dateToFormatted}`
            break;

          case 0:
            statusMsg = `Table showing ${visitorType} for today`
            break;

          case 1:
            statusMsg = `Table showing ${visitorType} for this week`
            break;

          case 2:
            statusMsg = `Table showing ${visitorType} for this month`
            break;
        
          default:
            break;
        }

        tableShowingStatusMsgContainer.innerHTML = statusMsg;
      }
    },
    parseFromDate(dateStr) {
      const dateFormats = 'DD/MM/YYYY';
      const date = dayjs(dateStr, dateFormats, true);
      const currentDate = dayjs(this.maxDate);
      const diffFuture = currentDate.diff(date);
      const minDate = dayjs(this.minDate);
      const diffPast = minDate.diff(date);

      return (date.isValid() && diffFuture >= 0 && diffPast <= 0) ? date : null;
    },
    handleFromDateEntered(val) {
      const date = this.parseFromDate(val);

      if (date) {
        this.dateFromFilter = date.format('YYYY-MM-DD');
      }
    },
    parseToDate(dateStr) {
      const dateFormats = 'DD/MM/YYYY';
      const date = dayjs(dateStr, dateFormats, true);
      const maxDate = dayjs(this.maxDate);
      const diffFuture = maxDate.diff(date);
      const fromDate = dayjs(this.dateFromFilter);
      const diffPast = fromDate.diff(date);

      return (date.isValid() && diffFuture >= 0 && diffPast <= 0) ? date : null;
    },
    handleToDateEntered(val) {
      const date = this.parseToDate(val);

      if (date) {
        this.dateToFilter = date.format('YYYY-MM-DD');
      }
    },
    ...mapActions('visitors', [
      'setFilters',
      'setVisitors',
      'setTodayCheckinsCount',
      'setMobileModalOn',
      'setSelectedEventIds',
      'setStoredEventIds',
      'setSelectedEvents',
    ]),
  },
};
</script>
<style>
.visitor-list {
    padding: 0 45px 0 45px;
  }

@media only screen and (max-width: 600px) {
  .visitor-list {
    padding: 0 12px 0 12px;
  }
}

.v-btn {
  text-transform: none !important;
}

.v-btn--active {
  background-color: #041e42;
  color: white !important;
}

.icon-color {
  color: #041e42 !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
  color: white !important;
}

.headerBtn .v-btn__content {
  justify-content: start !important;
}

.btnToggle {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.v-data-table-header-mobile__wrapper .v-data-table-header-mobile__select {
  display: none;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light table thead tr th{
  color: rgba(0, 0, 0, 0.87);
}

.v-date-picker-header.theme--light button {
  color: rgba(0, 0, 0, 0.87) !important;
}

.date-picker-label {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.datatable-header-container {
  align-items: baseline;
  justify-content: space-between;
}

.datatable-header-checkin {
  font-weight: normal;
  font-size: 1rem;
}

.visitor-type-filter-container {
  font-weight: normal;
  /* width: 12rem; */
  margin-right: 32px;
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.actions-button-container {
    font-weight: bold;
    width: 8rem;
    margin-left: 16px;
  }

.actions-button-container button {
  height: 48px !important;
}

.visitor-type-filter-container .v-input .v-input__control .v-input__slot{
  min-height: 48px !important;
}

.datatable-header-right {
  display: inherit;
  align-items: baseline;
}

.datatable-header-text {
  padding-top: 14px;
}

.apply-btn {
  margin-bottom: 1.3rem;
  margin-left: 1rem;
}

.expired-color {
  color: #DC5800;
}

.completed-color {
  color: #00AA45;

}

.phone-number--anchor {
  color: #000000DE !important;
}

/* button -- disabled */
.v-btn--disabled {
  background-color: rgba(0,0,0,.12) !important;
}

.white-icon {
  color:white !important;
}

.entry-requirements-icon {
  font-size: 21px !important;
  padding-right: 5px;
}

/* Spacing on select label -- disabled */
/* .v-select__slot .v-label {
  top:6px !important;
} */

/* button icon -- disabled */
.theme--light.v-btn.v-btn--disabled .v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}

/* visitor type filter border */
.visitor-type-filter-container .v-input .v-input__control fieldset {
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-width: thin;
}

/* visitor type filter background -- disabled */
.visitor-type-filter-container .v-input--is-readonly .v-input__control .v-input__slot {
  background-color: rgba(0,0,0,.06) !important;
}

/* visitor type filter text -- disabled */
.visitor-type-filter-container .v-input--is-readonly .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  color: rgba(0, 0, 0, 0.26) !important;
}

/* visitor type filter icon -- disabled */
.visitor-type-filter-container .v-input--is-readonly .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon .v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}

/* visitor type filter border -- focused */
.v-input--is-focused>.v-input__control .v-input__slot {
  border: 3px solid #407EC9 !important;
}
.v-menu__content.menuable__content__active .v-select-list .v-list-item.v-list-item--active {
  background-color: #041e42;
  color: white !important;
}

/* checkout button style */
#checkoutBtn .v-btn__content i {
  color: white;
}

/* date button group sytle */
.date-btn-group button:focus {
  color: #041e42;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:focus {
  border-width: 3px;
  border-color: #407EC9 !important;
}

/* school select dropdown */
.v-menu__content.v-autocomplete__content .v-list .v-list-item--link.v-list-item--active {
  background-color: #1A3B69;
  color: white !important;
}

/* dropdown */
.v-menu__content.menuable__content__active .v-list.v-sheet .v-list-item--link:hover {
  background-color: #6098D7;
}
.v-menu__content.menuable__content__active .v-list.v-sheet .v-list-item--link.v-list-item--highlighted {
  background-color: #6098D7;
}

/* date invalid error message */
#dateErrorMessage {
  font-size: 0%;
}

@media only screen and (max-width: 960px) {
  .datatable-header-container {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .datatable-header-right {
    justify-content: center;
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .visitor-type-filter-container {
    margin-top: 1rem;
    margin-right: 0px;
    /* width: 99%; */
  }

  .apply-btn {
    margin-top: 1rem;
    margin-left: 0;
    width: 100%;
  }

  #datatable-search-bar.v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none;
  }

  /* mobile data table style */
  /* .v-data-table__mobile-table-row {
    padding-left: 2rem !important;
  }
  .v-data-table__mobile-table-row td:first-child {
    position: relative !important;
    top: 1.95rem !important;
  }
  .v-data-table__mobile-table-row td:nth-child(2) {
    position: absolute;
    top: 3rem;
    right: -2rem;
    padding: 0;
  } */
}
</style>
